import { City } from "./city";

export class CompanyInfo {

    constructor(public id: number,
        public companyName: string,
        public logo: string,
        public email: string,
        public website: string,
        public address: string,
        public shortInfo: string,
        public longInfo: string,
        public primaryMobile: string,
        public secondayMobile: string,
        public instaLink: string,
        public fbLink: string,
        public googleMapLink: string,
        public latitide: number,
        public longitude: number,
        public city: City[] = [],
        public dateModified: Date

    ) {

    }
}
