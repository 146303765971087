export class ServiceList {

    constructor(public id: number,
        public serviceName: string,
        public serviceLogo: string,
        public shortInfo: string,
        public active: boolean,
    ) {

    }
}
