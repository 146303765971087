import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../servives/header.service';
import { CompanyInfo } from '../../common/company-info';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_PRODUCTLIST } from '../../utils/app-constants';
import { PortfolioList } from '../../common/portfolio-list';
import { ApiCallService } from '../../servives/api-call.service';
import { WebData } from '../../utils/web-data';
// import { MouseEvent } from '@agm/core';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit {

  [x: string]: any;

  lat = 51.678418;
  lng = 7.809007;

  portfolioList: PortfolioList[] = [];

  companyInfo: any = CompanyInfo
  imageObject: Array<object> = []

  constructor(private headerService: HeaderService,
    private localStorage: LocalStorageService,
    private apiCallService: ApiCallService,


  ) {

  }
  ngOnInit() {

    var productListObj = JSON.parse(this.localStorage.getItem(KEY_PRODUCTLIST))
    if (productListObj == null) {
      //list of portfolio
      this.getPortFolioList()

      //this.portfolioList = WebData.portfolio
      // this.localStorage.setObject(KEY_PRODUCTLIST, this.portfolioList)
      // this.setHomeClientImages(this.portfolioList)
      
    } else {
      //saving local data
      this.setHomeClientImages(productListObj)
    }

  }
  setHomeClientImages(productListObj: PortfolioList[]) {
    productListObj.forEach(element => {
      this.imageObject.push({
        image: element.clientLogo,
        thumbImage: element.clientLogo,
        alt: "alt of image",
        title: element.clientName
      });
    });
  }
  
  


  getPortFolioList() {
    this.apiCallService.getPortFolioListAPI().subscribe({
      next: (data: PortfolioList[]) => {
        this.portfolioList = data
        this.localStorage.setObject(KEY_PRODUCTLIST, this.portfolioList)
        this.setHomeClientImages(this.portfolioList)


      },
      error: (err: any) => {

      },
      complete() {

      },

    })
  }

}