import { environment } from '../../environments/environment';

export class AppConstants {


}

// export const BASE_API_URL: string = "http://localhost:8080/api";
export const BASE_API_URL: string = environment.API_URL;


export const WEB_KEY: string = "padmtech";
export const KEY_MENU: string = "menu";
export const KEY_COMPANYINFO: string = "companyInfo";
export const KEY_PRODUCTLIST: string = "productList";
export const KEY_SERVICE: string = "serviceList";
export const KEY_COURSEDETAILS: string = "courseDetailList";
export const KEY_DEVDETAILS: string = "devDetail";
export const KEY_DEV: string = "dev";
export const KEY_PARTNERS: string = "partners";
// export const KEY_COURCEDETAIL: string = "serviceDetail";









