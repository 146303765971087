import { Component, OnInit } from '@angular/core';

import { CompanyInfo } from '../../common/company-info';
import { HeaderService } from '../../servives/header.service';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_COMPANYINFO } from '../../utils/app-constants';
import { Router } from '@angular/router';


// lat: 18.520459,
// lng: 73.773973
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrl: './about.component.css',

})
export class AboutComponent implements OnInit {
  companyInfo: any = CompanyInfo

  constructor(private headerService: HeaderService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {

  }
  ngOnInit() {

    var currentTab = this.router.url

    if (currentTab.toLowerCase().includes("about")) {
      this.headerService.updateHeaderValue("About")
    }
    
    this.headerService.companyInfo
      .subscribe((companyInfodata: CompanyInfo) => {
        this.companyInfo = companyInfodata;
      });

    if (this.localStorageService.getObject(KEY_COMPANYINFO) != null) {
      var localCompanyInfo: any = CompanyInfo
      localCompanyInfo = this.localStorageService.getObject(KEY_COMPANYINFO);
      this.companyInfo = localCompanyInfo;

    }
  }
  actionFooterTab(activeTab: string, $event: MouseEvent): void {
    $event.preventDefault();
    var activeTabb = activeTab;
    this.headerService.updateHeaderValue(activeTabb)
    this.router.navigateByUrl("#/home")

  }
}
