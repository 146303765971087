<nav id="mainNavbar" class="navbar navbar-expand-sm navbar-light">
  <div class="container-fluid">
    <div class="leftside">

      <img routerLink="/home" (click)="actionHeaderTab('Home', $event)" src="{{companyInfo.logo}}" class="imglogo"
        id="logo" />
      <h4 class="companyName">{{companyInfo.companyName}}</h4>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
        (click)="collapsed = !this.collapsed">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    <div class="rightside">

      <div id="navbarSupportedContent" [ngClass]="{ collapse: collapsed, 'navbar-collapse': true }">

        <div *ngFor="let menuObj of menu">


          <ul class="navbar-nav ml-auto">

            <li class="nav-item"><a routerLink={{menuObj.routeLink}} (click)="actionHeaderTab(menuObj.menuName, $event)"
                [ngClass]="menuObj.menuName === activeTab ? 'menuactive text-white font-weight-bold' : 'menuinactive text-white'"
                class="nav-link menuinactive">{{menuObj.menuName}} </a></li>


          </ul>
        </div>
      </div>



    </div>
  </div>
</nav>