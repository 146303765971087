import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeComponent } from './components/home/home.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { ServicesComponent } from './components/services/services.component';
import { PortfolioDetailComponent } from './components/portfolio-detail/portfolio-detail.component';
import { ServicDetailComponent } from './components/servic-detail/servic-detail.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ProjectManagementComponent } from './components/project-management/project-management.component';
import { DevDetailsComponent } from './components/dev-details/dev-details.component';
import { DevelopmentsComponent } from './components/developments/developments.component';
import { PartnersComponent } from './components/partners/partners.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'portfoliodetail/:id', component: PortfolioDetailComponent },
  { path: 'serviceDetail/:id', component: ServicDetailComponent },
  { path: 'devDetail/:id', component: DevDetailsComponent },
  { path: 'productManagement', component: ProjectManagementComponent },
  { path: 'developments', component: DevelopmentsComponent },
  { path: 'partners', component: PartnersComponent },
  



  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    FormsModule, NgbModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  exports: [RouterModule]
})
export class AppRoutingModule { }
