import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../servives/header.service';
import { NgClass } from '@angular/common';
import { ApiCallService } from '../../servives/api-call.service';
import { Menu } from '../../common/menu';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_COMPANYINFO, KEY_MENU, WEB_KEY } from '../../utils/app-constants';
import { CompanyInfo } from '../../common/company-info';
import { City } from '../../common/city';
import { WebData } from '../../utils/web-data';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',



})
export class HeaderComponent implements OnInit {

  collapsed = true;

  activeTab: string = 'Home';
  menu: Menu[] = [];
  companyInfo: any = CompanyInfo
  city: City[] = [];


  constructor(private headerService: HeaderService,
    private apiCallService: ApiCallService,
    private localStorageService: LocalStorageService,
  ) {

  }
  
  activeTab$ = this.headerService.selectedHeader$;


  ngOnInit() {

    this.headerService.selectedHeader$.subscribe((value) => {
      this.activeTab = value;
    });
    this.activeTab = "Home"

    // JSON.parse(localStorage.getItem(KEY_MENU)!);
    var menuList = JSON.parse(this.localStorageService.getItem(KEY_MENU))

    if (menuList != null) {

      this.menu = menuList;

    } else {
      this.getMenuApi();

      // this.menu = WebData.menu;
      // this.localStorageService.setObject(KEY_MENU, this.menu);

    }

    //getting company info using getComapnyInfoApi()
    this.getComapnyInfoApi();

  }

  getMenuApi() {
    this.apiCallService.getMenuListAPI().subscribe(data => {
      //console.log("data: " +JSON.stringify(data));
      this.menu = data;
      this.localStorageService.setObject(KEY_MENU, this.menu);
    })
  }


  getComapnyInfoApi() {
    //getting address from api
    this.apiCallService.getAddressAPI().subscribe({
      next: (data: City[]) => {
        this.city = data;
      },
      error: (err: any) => {

      },
      complete() {

      },

    })
    //getting company info from api
    this.apiCallService.getComapanyInfoAPI().subscribe({
      next: (data: CompanyInfo[]) => {

        //getting company info from local storage in a variable
        var localStorageCompanyInfo = JSON.parse(this.localStorageService.getItem(KEY_COMPANYINFO));

        // demo dates:
        // var apiResponseDate   = new Date();
        // var localStorageDate = new Date();
        // localStorageDate.setDate(localStorageDate.getDate() - 2);

        if (localStorageCompanyInfo == null) {

          //saving api data in local storage if local storage is null
          console.log('Save api data in loacal storage')
          this.companyInfo = data[0];
          this.companyInfo.city = this.city;
          this.localStorageService.setObject(KEY_COMPANYINFO, this.companyInfo);
          this.localStorageService.setItem(WEB_KEY, this.companyInfo.companyName);

        } else {

          console.log('local storage '+JSON.stringify(localStorageCompanyInfo));
          //else getting local storage date and api response date                   
          var localStorageDate: Date = localStorageCompanyInfo?.dateModified;
          var apiResponseDate: Date = data[0].dateModified;
          console.log('local storage date: '+localStorageDate+' api response date: '+apiResponseDate);
          
          //comparing that if api response date is greater than local storage date 
          //if so then clear local storage and update it
          if(localStorageDate < apiResponseDate) {
            console.log('date is greater than previous date')
            //clear local storage
            this.localStorageService.clearSession();
            this.companyInfo = data[0];
            this.companyInfo.city = this.city;

            //update local storage with api response data
            this.localStorageService.setObject(KEY_COMPANYINFO, this.companyInfo);
            this.localStorageService.setItem(WEB_KEY, this.companyInfo.companyName);

          }else{
            this.companyInfo = localStorageCompanyInfo;
          }
        }

        //update company info
        this.headerService.updateComapnyInfoValue()
      },
      error: (err: any) => {

      },
      complete() {

      },

    })

  }
  actionHeaderTab(activeTab: string, $event: MouseEvent): void {
    $event.preventDefault();
    this.activeTab = activeTab;
  }

}
