<!-- About Section -->
<main class="main">

    <section id="about" class="about section">

        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
            <h2>About Us</h2>
        </div><!-- End Section Title -->

        <div class="container">

            <div class="row gy-4 justify-content-center">

                <div class="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
                    <p>
                        {{companyInfo.shortInfo}}
                    </p>
                    <p></p>

                </div>

                <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">

                    <p>We are thrilled to announce that <strong> Padmtech Software Solutions </strong>is now an official
                        partner with
                        <strong>Soft24 Technologies.</strong> This collaboration marks a significant milestone for both
                        companies as we
                        join forces to deliver innovative and cutting-edge solutions to our clients. Together, we are
                        committed to driving technological advancements and providing unparalleled service and support.

                        Stay tuned for more updates
                    </p>
                    <button (click)="actionFooterTab('Home', $event)" class="read-more"><span>Read More</span><i
                            class="bi bi-arrow-right"></i></button>
                </div>

            </div>

        </div>

    </section><!-- /About Section -->

</main>