import { Component, OnInit } from '@angular/core';
import { PortfolioList } from '../../common/portfolio-list';
import { HeaderService } from '../../servives/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { KEY_COURSEDETAILS, KEY_SERVICE } from '../../utils/app-constants';
import { LocalStorageService } from '../../servives/local-storage.service';
import { ServiceList } from '../../common/service-list';
import { Courses } from '../../common/courses';
import { WebData } from '../../utils/web-data';

@Component({
  selector: 'app-servic-detail',
  templateUrl: './servic-detail.component.html',
  styleUrl: './servic-detail.component.css'
})
export class ServicDetailComponent implements OnInit {

  courseDetail: any = Courses
  id = 0;
  currentTab = ""

  constructor(private headerService: HeaderService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {

  }

  ngOnInit() {

    this.id = this.route.snapshot.params['id'];
    this.currentTab = this.router.url

    if (this.currentTab.includes("service")) {
      this.headerService.updateHeaderValue("Services")
    }

    this.headerService.serviceDetail.subscribe(
      (productInfo: ServiceList) => {
        this.courseDetail = productInfo

      }
    )

    // var serviceListObj = JSON.parse(this.localStorageService.getItem(KEY_SERVICE))
    // if (serviceListObj == null) {

    //   this.localStorageService.setObject(KEY_SERVICE, WebData)
    // }
    
    if (this.localStorageService.getObject(KEY_COURSEDETAILS) != null) {
      this.courseDetail = this.localStorageService.getServiceDetailById(this.id)
    }

  }
  
  getImageStyle() {
    return {
      //this.image is the image URL that has been generated by your changeImage() function
      // backgroundImage: `url(${this.image})`,
      //It's important to redefine the background position because it will not be used if no `backgroundImage` is provided 
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }
  }
}
