<main class="main">
    <!-- Flow Section -->
    <section id="section1" class="contact section">
        <div class="container section-title" data-aos="fade-up">
            <h2>Product Management</h2>
            <h3>Agile Software Development Life Cycle (SDLC)</h3>
        </div>
        <div class="container" data-aos="fade-up" data-aos-delay="100">
            <p><strong>Software development life cycle (SDLC)</strong> is a phenomenon to <strong>design</strong>, <strong>develop</strong> and, <strong>test</strong> high-quality software. The primary aim of SDLC is to produce high-quality software that fulfills the customer requirement within times and cost estimates.</p>
            <p><strong>Agile Software Development Life Cycle (SDLC)</strong> is the combination of both iterative and incremental process models. It focuses on process adaptability and customer satisfaction by rapid delivery of working software product. Agile SDLC breaks down the product into small incremental builds. These builds are provided into iterations. </p>
            <div class="flex-container">
                <img src="https://static.javatpoint.com/tutorial/agile/images/agile-sdlc.jpg" alt="Agile Software Development Life Cycle (SDLC)" />
            </div>
            <p>In the agile SDLC development process, the customer is able to see the result and understand whether he/she is satisfied with it or not. This is one of the advantages of the agile SDLC model. One of its disadvantages is the absence of defined requirements so, it is difficult to estimate the resources and development cost.</p>
            <p><strong>Each iteration of agile SDLC consists of cross-functional teams working on various phases:</strong></p>
            <ol class="points">
                <li>Requirement gathering and analysis</li>
                <li>Design the requirements</li>
                <li>Construction/ iteration</li>
                <li>Deployment</li>
                <li>Testing</li>
                <li>Feedback</li>
            </ol>
            <h3 class="h3">Requirements gathering and analysis</h3>
            <p>In this phase, you must define the requirements. You should explain business opportunities and plan the time and effort needed to build the project. Based on this information, you can evaluate technical and economic feasibility. </p>
            <h3 class="h3">Design the requirements</h3>
            <p>When you have identified the project, work with stakeholders to define requirements. You can use the user flow diagram or the high-level UML diagram to show the work of new features and show how it will apply to your existing system.</p>
            <h3 class="h3">Construction/ Iteration</h3>
            <p>When the team defines the requirements, the work begins. The designers and developers start working on their project. The aims of designers and developers deploy the working product within the estimated time. The product will go into various stages of improvement, so it includes simple, minimal functionality. </p>
            <h3 class="h3">Deployment</h3>
            <p>In this phase, the team issues a product for the user's work environment.</p>
            <h3 class="h3">Testing</h3>
            <p>In this phase, the Quality Assurance team examine the product's performance and look for the bug.</p>
            <h3 class="h3">Feedback</h3>
            <p>After releasing of the product, the last step is to feedback it. In this step, the team receives feedback about the product and works through the feedback.</p>
        </div>
    </section>
</main>