<!-- <main class"> -->

<!-- Portfolio Section -->
<section class="portfolio section ">

    <!-- Section Title -->
    <div class="container section-title" data-aos="fade-up">
        <h2>Portfolio</h2>
        <p>{{companyName}} is a dynamic web development and design company based in Pune, India. We specialize
            in a broad range of services, including: development,training software product etc</p>
    </div><!-- End Section Title -->

    <div class="container">

        <div class="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">

            <ul class="portfolio-filters isotope-filters" data-aos="fade-up" data-aos-delay="100">
                <li data-filter="*" class="filter-active">All</li>
                <li data-filter=".filter-app">App</li>
                <li data-filter=".filter-product">Card</li>
                <li data-filter=".filter-branding">Web</li>
            </ul><!-- End Portfolio Filters -->
            <!-- <div class="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200"> -->

            <div class="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200">

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-app"
                    *ngFor="let product of portfolioList" (click)="detailPage(product)">
                    <div class="containerimgDiv" style="background-color: rgb(136, 134, 134);">
                        <img src="{{product.clientLogo}}" class="container_img" alt="">
                    </div>

                    <div class="portfolio-info">
                        <h4>{{product.clientName}}</h4>
                        <p>{{product.shortInfo}}</p>
                        <!-- <a href="{{product.clientLogo}}" title="App 1" data-gallery="portfolio-gallery-app"
                            class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a> -->
                        <!-- <a href="portfolio-details.html" title="More Details" class="details-link"><i
                                class="bi bi-link-45deg"></i></a> -->
                    </div>
                </div><!-- End Portfolio Item -->

            </div><!-- End Portfolio Container -->

        </div>

    </div>

</section><!-- /Portfolio Section -->
<!-- </main> -->