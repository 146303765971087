<section id="services" class="services section light-background">

    <!-- Section Title -->
    <div class="container section-title" data-aos="fade-up">
        <h2>Partners</h2>
    </div><!-- End Section Title -->



    <div class="container">
        <ul class="cards" >
            <li class="cards__item" *ngFor="let ptnObj of ptns" data-aos="fade-up"
            data-aos-delay="100">
                <div class="card">
                <div class="">
                    <img src="{{ptnObj.ptnLogo}}" class="img-fluid" alt="">
                </div>
                <div class="card__content" data-aos="fade-up" data-aos-delay="200">
                    <div class="card__title">{{ptnObj.ptnName}}</div>
                    <!-- <p class="card__text">This is the shorthand for flex-grow, flex-shrink and flex-basis combined. The second and third parameters (flex-shrink and flex-basis) are optional. Default is 0 1 auto. </p> -->
                    <p class="divellipsis">{{ptnObj.shortInfo}}</p>
                    <!-- <button class="btn btn--block card__btn">Button</button> -->
                    <!-- <a class="read-more"><span>Read More</span><i 
                    class="bi bi-arrow-right"></i></a>-->
                </div>
                </div>
            </li>
        </ul>
    </div>
</section>
