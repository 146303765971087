import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i0 from '@angular/core';
import { forwardRef, EventEmitter, Directive, Input, Output, HostBinding, HostListener, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
const MDB_CHECKBOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line no-use-before-define, @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => MdbCheckboxDirective),
  multi: true
};
class MdbCheckboxChange {
  element;
  checked;
}
class MdbCheckboxDirective {
  get checked() {
    return this._checked;
  }
  set checked(value) {
    this._checked = coerceBooleanProperty(value);
  }
  _checked = false;
  get value() {
    return this._value;
  }
  set value(value) {
    this._value = value;
  }
  _value = null;
  get disabled() {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
  }
  _disabled = false;
  checkboxChange = new EventEmitter();
  get isDisabled() {
    return this._disabled;
  }
  get isChecked() {
    return this._checked;
  }
  onCheckboxClick() {
    this.toggle();
  }
  onBlur() {
    this.onTouched();
  }
  constructor() {}
  get changeEvent() {
    const newChangeEvent = new MdbCheckboxChange();
    newChangeEvent.element = this;
    newChangeEvent.checked = this.checked;
    return newChangeEvent;
  }
  toggle() {
    if (this.disabled) {
      return;
    }
    this._checked = !this._checked;
    this.onChange(this.checked);
    this.onCheckboxChange();
  }
  onCheckboxChange() {
    this.checkboxChange.emit(this.changeEvent);
  }
  // Control Value Accessor Methods
  onChange = _ => {};
  onTouched = () => {};
  writeValue(value) {
    this.value = value;
    this.checked = !!value;
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  static ngAcceptInputType_checked;
  static ngAcceptInputType_disabled;
  static ɵfac = function MdbCheckboxDirective_Factory(t) {
    return new (t || MdbCheckboxDirective)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: MdbCheckboxDirective,
    selectors: [["", "mdbCheckbox", ""]],
    hostVars: 2,
    hostBindings: function MdbCheckboxDirective_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("click", function MdbCheckboxDirective_click_HostBindingHandler() {
          return ctx.onCheckboxClick();
        })("blur", function MdbCheckboxDirective_blur_HostBindingHandler() {
          return ctx.onBlur();
        });
      }
      if (rf & 2) {
        i0.ɵɵhostProperty("disabled", ctx.isDisabled)("checked", ctx.isChecked);
      }
    },
    inputs: {
      checked: "checked",
      value: "value",
      disabled: "disabled"
    },
    outputs: {
      checkboxChange: "checkboxChange"
    },
    features: [i0.ɵɵProvidersFeature([MDB_CHECKBOX_VALUE_ACCESSOR])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MdbCheckboxDirective, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: '[mdbCheckbox]',
      providers: [MDB_CHECKBOX_VALUE_ACCESSOR]
    }]
  }], () => [], {
    checked: [{
      type: Input,
      args: ['checked']
    }],
    value: [{
      type: Input,
      args: ['value']
    }],
    disabled: [{
      type: Input,
      args: ['disabled']
    }],
    checkboxChange: [{
      type: Output
    }],
    isDisabled: [{
      type: HostBinding,
      args: ['disabled']
    }],
    isChecked: [{
      type: HostBinding,
      args: ['checked']
    }],
    onCheckboxClick: [{
      type: HostListener,
      args: ['click']
    }],
    onBlur: [{
      type: HostListener,
      args: ['blur']
    }]
  });
})();
class MdbCheckboxModule {
  static ɵfac = function MdbCheckboxModule_Factory(t) {
    return new (t || MdbCheckboxModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MdbCheckboxModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, FormsModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MdbCheckboxModule, [{
    type: NgModule,
    args: [{
      declarations: [MdbCheckboxDirective],
      exports: [MdbCheckboxDirective],
      imports: [CommonModule, FormsModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MDB_CHECKBOX_VALUE_ACCESSOR, MdbCheckboxChange, MdbCheckboxDirective, MdbCheckboxModule };
