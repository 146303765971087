import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CompanyInfo } from '../common/company-info';
import { KEY_COMPANYINFO } from '../utils/app-constants';
import { LocalStorageService } from './local-storage.service';
import { PortfolioList } from '../common/portfolio-list';
import { ServiceList } from '../common/service-list';
import { Devs } from '../common/devs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private localStorageService: LocalStorageService) { }

  selectedHeaderSubject$ = new BehaviorSubject("/home")
  selectedHeader$: Observable<string> = this.selectedHeaderSubject$.asObservable();

  companyInfoSubject = new BehaviorSubject(CompanyInfo)
  companyInfo: any = this.companyInfoSubject.asObservable()

  productDetailSubject = new BehaviorSubject(PortfolioList)
  productDetail: any = this.productDetailSubject.asObservable()


  serviceListSubject = new BehaviorSubject(ServiceList)
  serviceDetail: any = this.serviceListSubject.asObservable()

  devListSubject = new BehaviorSubject(Devs)
  devDetail: any = this.devListSubject.asObservable()

  updateHeaderValue(header: string) {
    this.selectedHeaderSubject$.next(header)
  }

  updateComapnyInfoValue() {
    var localCompanyInfo: any = CompanyInfo
    localCompanyInfo = this.localStorageService.getObject(KEY_COMPANYINFO);

    this.companyInfoSubject.next(localCompanyInfo)

  }
  updateProductDetail(product1: any) {
    this.productDetailSubject.next(product1)
  }
  callServiceDetail(serviceDet: any) {
    this.serviceListSubject.next(serviceDet)
  }
  callDevDetail(devDet: any) {
    this.devListSubject.next(devDet)
  }
}
