import { Component, OnInit } from '@angular/core';
import { ServiceList } from '../../common/service-list';
import { Courses } from '../../common/courses';
import { Router } from '@angular/router';
import { ApiCallService } from '../../servives/api-call.service';
import { HeaderService } from '../../servives/header.service';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_COURSEDETAILS, KEY_DEV, KEY_DEVDETAILS, KEY_SERVICE } from '../../utils/app-constants';
import { WebData } from '../../utils/web-data';
import { Devs } from '../../common/devs';

@Component({
  selector: 'app-developments',
  templateUrl: './developments.component.html',
  styleUrl: './developments.component.css'
})
export class DevelopmentsComponent implements OnInit {

  devs: Devs[] = [];
  courseDetailList: Courses[] = [];

  show = false

  constructor(private routes: Router,
  private apiCallService: ApiCallService,
  private headerService: HeaderService,
  private localStorage: LocalStorageService,
  private router: Router

  ) { }

  ngOnInit() {
    
    var currentTab = this.router.url
    if (currentTab.toLowerCase().includes("developments")) {
      this.headerService.updateHeaderValue("Developments")
    }

    var devListObj = JSON.parse(this.localStorage.getItem(KEY_DEV))
    if (devListObj == null) {
      //list of developments
      this.getDevsList()
      // this.serviceList = WebData.service
      // this.localStorage.setObject(KEY_SERVICE, this.serviceList)
    } else {
      this.devs = devListObj
    }
  }

  getDevsList() {
    this.apiCallService.getDevListAPI().subscribe({
      next: (data: Devs[]) => {
        this.devs = data
        this.localStorage.setObject(KEY_DEVDETAILS, this.devs)

      },
      error: (err: any) => {

      },
      complete() {

      },

    })
  }
  
  detailPage(devs: Devs) {
    this.routes.navigate(["/devDetail", devs.id])
    this.headerService.callDevDetail(devs)

  }
}
