export class PortfolioList {

    constructor(public id: number,
        public clientName: string,
        public clientLogo: string,
        public shortInfo: string,
        public clientCategory: string,
        public active: boolean,
    ) {

    }
}
