import { Component, OnInit } from '@angular/core';
import { ServiceList } from '../../common/service-list';
import { HeaderService } from '../../servives/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_DEV, KEY_DEVDETAILS, KEY_SERVICE } from '../../utils/app-constants';
import { WebData } from '../../utils/web-data';
import { Devs } from '../../common/devs';

@Component({
  selector: 'app-dev-details',
  templateUrl: './dev-details.component.html',
  styleUrl: './dev-details.component.css'
})
export class DevDetailsComponent implements OnInit {

  devDetail: any = Devs
  id = 0;
  currentTab = ""

  constructor(private headerService: HeaderService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {

  }

  ngOnInit(): void {

    this.id = this.route.snapshot.params['id'];
    this.currentTab = this.router.url
    if (this.currentTab.includes("developments")) {
      this.headerService.updateHeaderValue("Developments")
    }

    this.headerService.devDetail.subscribe(
      (productInfo: Devs) => {
        this.devDetail = productInfo

      }
    )


    if (this.localStorageService.getObject(KEY_DEVDETAILS) != null) {
      this.devDetail = this.localStorageService.getDevDetailById(this.id)
    }
  }

  
  getImageStyle() {
    return {
      //this.image is the image URL that has been generated by your changeImage() function
      // backgroundImage: `url(${this.image})`,
      //It's important to redefine the background position because it will not be used if no `backgroundImage` is provided 
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }
  }
}
