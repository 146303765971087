import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})

export class AppComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);
  }
}
// export class AppComponent {
//   ngOnInit() {
//     this.spinner.show();
//   }
//   title = 'padmtech_web';
//   constructor(){
//     console.log(environment.apiUrl);
//   }
// }
