import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pipe, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AlertifyService } from '../servives/alertify.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  private isErrorDisplayed = false;

  constructor(private alertify: AlertifyService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log('Http intercepter started')
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage = this.setError(error);
          console.log(error);
          if (!this.isErrorDisplayed) {
            alert(errorMessage);
            this.isErrorDisplayed = true;      
          }
          //alert(errorMessage);
          return throwError(errorMessage);
        })
      );
  }

  setError(error: HttpErrorResponse): string {
    let errorMessage = 'Server is down. Please try again later.';

    if(error.error instanceof ErrorEvent) {
      //Server side error
      errorMessage = error.error.message;
    } else {
      //Client side error
      if (error.status!==0){
        errorMessage = 'Something went wrong. ' + error.error.message;
      }
    }
    return errorMessage;
  }

}