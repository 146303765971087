export class Devs {
    constructor(public id: number,
        public devName: string,
        public devLogo: string,
        public shortInfo: string,
        public active: boolean,
    ) {

    }
}
