import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../servives/header.service';
import { CompanyInfo } from '../../common/company-info';
import { KEY_COMPANYINFO } from '../../utils/app-constants';
import { LocalStorageService } from '../../servives/local-storage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',

})
export class FooterComponent implements OnInit {


  activeTab = '';
  companyInfo: any = CompanyInfo

  constructor(private headerService: HeaderService,
    private localStorageService: LocalStorageService
  ) {

  }

  ngOnInit() {

    this.headerService.companyInfo
      .subscribe((companyInfodata: CompanyInfo) => {
        this.companyInfo = companyInfodata;
      });

    if (this.localStorageService.getObject(KEY_COMPANYINFO) != null) {
      var localCompanyInfo: any = CompanyInfo
      localCompanyInfo = this.localStorageService.getObject(KEY_COMPANYINFO);
      this.companyInfo = localCompanyInfo;

    }

  }

  //to change active tab when clicked on any tab
  actionFooterTab(activeTab: string, $event: MouseEvent): void {
    $event.preventDefault();
    this.activeTab = activeTab;
    this.headerService.updateHeaderValue(this.activeTab)

  }

  openUrl(url: any) {
    console.log("url  " + JSON.stringify(this.companyInfo))
    // window.open(url);
  }

}
