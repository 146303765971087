/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
if (environment.production) {
  console.log = function (): void { };
  console.debug = function (): void { };
  console.warn = function (): void { };
  console.info = function (): void { };
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
  ]
});