import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private apiCount = 0;
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  constructor(private spinner: NgxSpinnerService) { }

  showLoader() {
    if (this.apiCount === 0) {
      this.isLoadingSubject.next(true);
      //show() is default method to show spinner
      this.spinner.show();
    }  
    this.apiCount++;
  }

  hideLoader() {
    this.apiCount--;
    if (this.apiCount === 0) {
      this.isLoadingSubject.next(false);
      //hide() is default method to show spinner
      this.spinner.hide();
    }
  }
}
