<!-- <p>contact works!</p>
<div class="my-google-map">
    <google-map [options]="mapOptions">
        <map-marker [position]="marker.position"></map-marker>
    </google-map>

</div> -->

<main class="main">
    <!-- Contact Section -->
    <section id="contact" class="contact section">

        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
            <h2>Contact Us</h2>
            <p>Feel free to connect with us for any of your needs regarding our services. Our support team is ready to
                solve any of your issues. Just push a text to us and we will get back to you immediately.</p>
        </div><!-- End Section Title -->

        <div class="container" data-aos="fade-up" data-aos-delay="100">

            <div class="row gy-4">

                <div class="col-lg-5">

                    <div class="info-wrap">
                        <div *ngFor="let address of companyInfo.city">
                            <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                                <i class="fas fa-home mr-3"></i>
                                <div>
                                    <h3>{{address.cityName}} Address</h3>
                                    <p> {{address.cityAddress}} {{address.cityName}} {{address.country}}
                                        {{address.pinCode}}</p>
                                </div>
                            </div><!-- End Info Item -->

                        </div>


                        <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                            <i class="fas fa-phone mr-3"></i>
                            <div>
                                <h3>Call Us</h3>
                                <p>{{companyInfo.primaryMobile}}</p>
                            </div>
                        </div><!-- End Info Item -->

                        <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                            <i class="fas fa-envelope mr-3"></i>
                            <div>
                                <h3>Email Us</h3>
                                <p>{{companyInfo.email}}</p>
                            </div>
                        </div><!-- End Info Item -->

                        <!-- <iframe
                            src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d15132.74435629926!2d73.76498000000001!3d18.520491!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDMxJzEzLjgiTiA3M8KwNDYnMjYuNCJF!5e0!3m2!1sen!2sus!4v1722782972429!5m2!1sen!2sus"
                            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe> -->

                        <iframe src="https://maps.google.com/maps?q=18.520459, 73.773973&z=15&output=embed" width="360"
                            height="270" frameborder="0" style="border:0"></iframe>

                        <!-- <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48389.78314118045!2d-74.006138!3d40.710059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a22a3bda30d%3A0xb89d1fe6bc499443!2sDowntown%20Conference%20Center!5e0!3m2!1sen!2sus!4v1676961268712!5m2!1sen!2sus"
                            frameborder="0" style="border:0; width: 100%; height: 270px;" allowfullscreen=""
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
                    </div>
                </div>

                <div class="col-lg-7">
                    
                    <form  #contactForm="ngForm" (ngSubmit)="addContact(contactForm)"class="php-email-form" data-aos="fade-up"
                        data-aos-delay="200">
                        <div [innerHTML]="successMsg"></div>
                        <div class="row gy-4">

                            <div class="col-md-6">
                                <label for="name-field" class="pb-2">Your Name</label>
                                <input  #contactName="ngModel" pattern="[a-zA-Z  ]+$" type="text" name="name" [(ngModel)]="contactObj.name" id="name-field" class="form-control" required minlength="3" maxlength="20">
                                <span *ngIf="contactName.invalid && contactName.touched && !contactForm.submitted" style="color: red;">
                                    <span *ngIf="contactName.errors?.['required']">Name is required.</span>
                                    <span *ngIf="contactName.errors?.['minlength']">Name must be at least 3 and at most 20 characters long.</span>
                                </span>                            </div>

                            <div class="col-md-6">
                                <label for="email-field" class="pb-2">Your Email</label>
                                <input type="email" email #contactEmail="ngModel" class="form-control" name="email" [(ngModel)]="contactObj.email" id="email-field" required>
                                <span *ngIf="contactEmail.invalid && contactEmail.touched && !contactForm.submitted" style="color: red;">
                                    <span *ngIf="contactEmail.errors?.['required']">Email is required.</span>
                                    <span *ngIf="contactEmail.errors?.['email']">Please enter a valid email.</span>
                                </span>                            
                            </div>

                            <div class="col-md-6">
                                <label for="email-field" class="pb-2">Your Mobile Number</label>
                                <input type="text"  #contactNumber="ngModel" class="form-control" name="mobile" [(ngModel)]="contactObj.mobile" id="mobile-field" required minlength="10" maxlength="10" pattern = "^[0-9]{10}$">
                                <span *ngIf="contactNumber.invalid && contactNumber.touched && !contactForm.submitted" style="color: red;">
                                    <span *ngIf="contactNumber.errors?.['required']">Mobile Number is required.</span>
                                    <span *ngIf="contactNumber.errors?.['minlength']">Please enter 10 digit mobile number. </span>
                                    <span *ngIf="contactNumber.errors?.['maxlength']">Please enter 10 digit mobile number. </span>
                                    <span *ngIf="contactNumber.errors?.['pattern']">Please enter numbers only.</span>
                                </span>                            
                            </div>

                            <div class="col-md-6">
                                <label for="subject-field" class="pb-2">Subject</label>
                                <input #contactSubject="ngModel"  subject type="text" class="form-control" name="subject" [(ngModel)]="contactObj.subject" id="subject-field" required minlength="3" maxlength="15">
                                <span *ngIf="contactSubject.invalid && contactSubject.touched && !contactForm.submitted" style="color: red;">Subject is required.</span>
                            </div>

                            <div class="col-md-12">
                                <label for="message-field" class="pb-2">Message</label>
                                <textarea #contactMessage="ngModel" class="form-control" name="message" [(ngModel)]="contactObj.message" rows="10" id="message-field"
                                    required></textarea>
                                    <span *ngIf="contactMessage.invalid && contactMessage.touched && !contactForm.submitted" style="color: red;">Please enter message</span>
                            </div>

                            <div class="col-md-12">
                                <label for="subject-field" class="pb-2">Your CV</label>
                                <input #contactAttachment="ngModel" accept=".pdf,.doc,.docx" (change)="onFileChange($event)" type="file" class="form-control" name="attachment" [(ngModel)]="contactObj.attachment" id="attachment-field" required>
                                <span *ngIf="contactAttachment.invalid && contactAttachment.touched && !contactForm.submitted" style="color: red;">CV is required and please attach only pdf or doc type file.</span>
                            </div>

                            <div class="col-md-12 text-center">
                                <div class="loading">Loading</div>
                                <div class="error-message"></div>
                                <div class="sent-message">Your message has been sent. Thank you!</div>

                                <button type="submit">Send Message</button>
                            </div>

                        </div>
                    </form>
                </div><!-- End Contact Form -->

            </div>

        </div>

    </section><!-- /Contact Section -->

</main>