import { Component, OnInit } from '@angular/core';
import { Partners } from '../../common/partners';
import { Router } from '@angular/router';
import { ApiCallService } from '../../servives/api-call.service';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_PARTNERS } from '../../utils/app-constants';
import { HeaderService } from '../../servives/header.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrl: './partners.component.css'
})
export class PartnersComponent implements OnInit {
  
  ptns: Partners[] = [];

  show = false
  constructor(private routes: Router,
    private apiCallService: ApiCallService,
    private headerService: HeaderService,
    private localStorage: LocalStorageService,
    private router: Router
  ) { }

  ngOnInit() {
    var currentTab = this.router.url
    if (currentTab.toLowerCase().includes("partners")) {
      this.headerService.updateHeaderValue("Partners")
    }

    var ptnsListObj = JSON.parse(this.localStorage.getItem(KEY_PARTNERS))

    if (ptnsListObj == null) {
      //getting partners list
      this.getPtnsList()
      // this.serviceList = WebData.service
      // this.localStorage.setObject(KEY_SERVICE, this.serviceList)
    } else {
      this.ptns = ptnsListObj
    }
  }

  //partners list
  getPtnsList() {
    this.apiCallService.getPtnListAPI().subscribe({
      next: (data: Partners[]) => {
        this.ptns = data
        this.localStorage.setObject(KEY_PARTNERS, this.ptns)

      },
      error: (err: any) => {
        console.log(err);
      },
      complete() {

      },

    })
  }

}
